<template>
  <Button style="
      background-color: transparent;
      font-size: 200%;
      position: absolute;
      top: 0;
      right: 0;
      margin: 1rem;
    " @click="toggle">
    <span v-if="$i18n.locale == 'pt'"><img alt="Português" class="flag" src="@/assets/portugal.png" /></span>
    <span v-if="$i18n.locale == 'es'"><img alt="Español" class="flag" src="@/assets/spain.png" /></span>
    <span v-if="$i18n.locale == 'en'"><img alt="English" class="flag" src="@/assets/united-kingdom.png" /></span>
    <span v-if="$i18n.locale == 'fr'"><img alt="Francais" class="flag" src="@/assets/france-flag-icon.webp" /></span>
  </Button>
  <OverlayPanel class="accent" ref="op">
    <ul class="flag-list">
      <li @click="changeLanguage('pt', true)"><img alt="Português" class="flag" src="@/assets/portugal.png" /></li>
      <li @click="changeLanguage('es', true)"><img alt="Español" class="flag" src="@/assets/spain.png" /></li>
      <li @click="changeLanguage('en', true)"><img alt="English" class="flag" src="@/assets/united-kingdom.png" /></li>
      <li @click="changeLanguage('fr', true)"><img alt="Francais" class="flag" src="@/assets/france-flag-icon.webp" /></li>
    </ul>
  </OverlayPanel>
  <div class="loginPage" v-if="showLoginPage">
    <div class="login-form" :class="{ 'change-password-form': state == CHANGE_PASSWORD_STATE }">
      <div class="logo">
        <img @click="stats_visible = !stats_visible" alt="Vue logo" class="logo" src="@/assets/zone_soft_logo.svg" />
      </div>
      <h2>{{ titleLabel }}</h2>

      <div class="field">
        <label for="login">{{ $t("login.login") }}</label>
        <input id="login" type="text" v-model="login" @keyup.enter="doAction"
          class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
      </div>
      <!--All passwords are identified by pass1, pass2, pass3-->
      <div class="field">
        <label for="pass1">{{ loginPasswordLabel }}</label>
        <input id="pass1" type="password" @keyup.enter="doAction" v-model="password"
          class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
      </div>
      <div v-if="state == CHANGE_PASSWORD_STATE" class="field">
        <label for="pass2">{{ $t("login.new_password") }}</label>
        <input id="pass2" type="password" @keyup.enter="doAction" v-model="newPassword1"
          class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
      </div>
      <div v-if="state == CHANGE_PASSWORD_STATE" class="field">
        <label for="pass3">{{ $t("login.new_password_conf") }}</label>
        <input id="pass3" type="password" @keyup.enter="doAction" v-model="newPassword2"
          class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
      </div>
      <div class="field inline">
        <Button @click="doAction">{{ $t("login.submit") }}</Button>
        <a v-if="state === LOGIN_STATE" @click="state = CHANGE_PASSWORD_STATE">{{ $t("login.change_password") }}</a>
        <a v-if="state === CHANGE_PASSWORD_STATE" @click="state = LOGIN_STATE">{{ $t("login.cancel_change_password")
        }}</a>
      </div>
    </div>
    <div :class="{ hidden: !stats_visible }" class="version-holder">{{ version }}/{{ serverVersion.date }}</div>
    <div :class="{ hidden: !stats_visible }" class="hash-holder">{{ hash }}/{{ serverVersion.hash }}</div>
  </div>
</template>

<script setup>
import OverlayPanel from "primevue/overlaypanel";

import { useRouter } from "vue-router";

import { ref, computed, onMounted, onBeforeMount } from "vue";

import { useUserStore } from "../../stores/user";

import { useToast } from "primevue/usetoast";

import { useI18n } from "vue-i18n";

const version = _APP_VERSION_;

const hash = _APP_COMMIT_HASH_;

const LOGIN_STATE = "LOGIN_STATE";
const CHANGE_PASSWORD_STATE = "CHANGE_PASSWORD_STATE";
const state = ref(LOGIN_STATE);

const { t, locale } = useI18n();

const toast = useToast();

const userStore = useUserStore();

const router = useRouter();

const name = ref("");
const login = ref(import.meta.env.VITE_LOGIN);
const password = ref(import.meta.env.VITE_PASSWORD);
const newPassword1 = ref("");
const newPassword2 = ref("");

const changeLanguage = (lang, t) => {
  locale.value = lang;
  localStorage.setItem("lang", lang);
  if (t) op.value.toggle();
};

const op = ref();
const toggle = (event) => {
  op.value.toggle(event);
};

const reset = () => {
  name.value = "";
  login.value = "";
  password.value = "";
  newPassword1.value = "";
  newPassword2.value = "";
};

const titleLabel = computed(() => {
  return state.value === LOGIN_STATE
    ? t("login.title")
    : t("login.title_change_password");
});

const loginPasswordLabel = computed(() => {
  return state.value === LOGIN_STATE
    ? t("login.password")
    : t("login.old_password");
});

const doAction = () => {
  if (state.value === LOGIN_STATE) {
    doLogin();
  } else {
    doChangePassword();
  }
};

const doChangePassword = async () => {
  if (newPassword1.value !== newPassword2.value) {
    toast.add({
      severity: "error",
      summary: t("login.change_password_error"),
      detail: t("login.passwords_dont_match"),
      group: "c",
      life: 6000,
    });
    return;
  }

  if (newPassword1.value.length < 5) {
    toast.add({
      severity: "error",
      summary: t("login.change_password_error"),
      detail: t("login.password_too_short"),
      group: "c",
      life: 6000,
    });
    return;
  }

  const data = {
    login: login.value,
    password: password.value,
    new_password: newPassword1.value,
  };

  try {
    await userStore.changePassword(data);
    toast.add({
      severity: "success",
      summary: t("login.change_password_success"),
      detail: t("login.password_changed_text"),
      group: "c",
      life: 6000,
    });
    state.value = LOGIN_STATE;
    reset();
  } catch (error) {
    console.log("CHANGE PASSWORD ERROR", error);
  }
};

const doLogin = async () => {
  const data = {
    nome: name.value,
    login: login.value,
    password: password.value,
  };

  try {
    await userStore.login(data);
    router.replace("/");
  } catch (error) {
    console.log("LOGIN ERROR", error);
  }
};


const serverVersion = ref({
  date: '',
  hash: ''
});

function getDateAndTimeAsString(str) {
  const date = new Date(str)


  let day = date.getDate()
  if (day < 10) {
    day = `0${day}`
  }
  let month = date.getMonth() + 1
  if (month < 10) {
    month = `0${month}`
  }
  let year = date.getFullYear()
  let hour = date.getHours()
  if (hour < 10) {
    hour = `0${hour}`
  }
  let minutes = date.getMinutes()
  if (minutes < 10) {
    minutes = `0${minutes}`
  }


  return `${year}${month}${day}.${hour}${minutes}`
}


onMounted(() => {
  userStore.getServerVersion().then((res) => {
    serverVersion.value.date = getDateAndTimeAsString(res?.data[1] ?? '');
    serverVersion.value.hash = (res?.data[0] ?? '').substring(0, 7);
  });

});

const showLoginPage = ref(false);

onBeforeMount(() => {
  userStore.ping().finally(() => {
    showLoginPage.value = true
  });
})

const stats_visible = ref(false);
</script>

<style lang="scss" scoped>
.loginPage {
  //gradient
  width: 100vw;
  height: 100vh;
  background: #f6f6f6;
  background: linear-gradient(45deg, #1e1e2d, #e84e0f);
  display: flex;
  justify-content: center;
  align-items: center;

  .login-form,
  .change-password-form {
    width: 500px;
    height: 500px;
    background-color: #fff;
    background: linear-gradient(45deg, #eb6835, #292931);
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      color: #1e1e2d;
      text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.2);
    }

    label {
      color: white;
      font-size: 110%;
    }

    button {
      background-color: #e84e0f;
      border-color: #e84e0f;
    }

    .field {
      width: 75%;

      &.inline {
        position: relative;

        a {
          margin-left: 10px;
          position: absolute;
          display: inline-block;
          bottom: 0px;
          font-size: 80%;
          color: white;
          border-bottom: 1px solid white;
          padding-bottom: 2px;
          cursor: pointer;
          right: 0;
        }
      }
    }
  }

  .change-password-form {
    height: 600px;
  }

  .version-holder {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px;
    color: white;
    font-size: 12px;

    &.hidden {
      opacity: 0;
    }
  }

  .hash-holder {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
    color: white;
    font-size: 12px;

    &.hidden {
      opacity: 0;
    }
  }
}

ul.flag-list {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    cursor: pointer;
    font-size: 180%;
  }
}
</style>
