<script setup>
import { storeToRefs } from "pinia";
import Main from "@/layout/MainResp.vue";
import { useMainStore } from "./stores/main.js";
import { useMiscStore } from "./stores/misc";
import { useToast } from "primevue/usetoast";
import { watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const { t, locale } = useI18n();

const miscStore = useMiscStore();

const mainStore = useMainStore();

const { error } = storeToRefs(mainStore);
const toast = useToast();

const router = useRouter();

let lastErrorDate = new Date().getTime();
let lastErrorMessage = "";

//watch error
watch(error, (val) => {
  if (val) {
    const msg = t("system." + val?.response?.data?.code) || "Unknown Error";

    if (msg === lastErrorMessage) {
      let now = new Date().getTime();
      if (now - lastErrorDate < 1000) {
        return;
      }
    }

    lastErrorDate = new Date().getTime();
    lastErrorMessage = msg;

    if (!(val?.response?.data?.code ?? '').startsWith("auth.")) {
      toast.add({
        group: "c",
        severity: "error",
        summary: "Error",
        detail: msg,
        life: 6000,
      });
    }
  }
  if (val?.response?.status) {
    if (val?.response?.status === 401 || val?.response?.status === 403) {
      router.push("/login");
    }
  }
});

const getSystemLanguage = () => {
  const lang = navigator.language;
  if (lang) {
    const l = lang.split("-")[0];
    if (['en', 'us'].includes(l)) {
      return "en";
    }
    if (['pt', 'es'].includes(l)) {
      return l;
    }
  }
  return "pt";
}

const getLanguage = () => {
  const lang = localStorage.getItem("lang");
  if (lang) {
    return lang;
  }
  return getSystemLanguage();
};

const changeLanguage = (lang, t) => {
  locale.value = lang;
  localStorage.setItem("lang", lang);
};

onMounted(() => {
  changeLanguage(getLanguage());
  getSoftwareList();
});

const getSoftwareList = () => {
  miscStore.getSoftwareList();
};

const reload = () => {
  // window.location.reload();
};
</script>
<template>
  <Main> </Main>
  <Toast group="bl" position="bottom-left" />
  <Toast group="c" position="center" :pt="{
    container: { style: { position: 'relative', top: '-300px' } }
  }" />
  <Toast group="tr" position="top-right" />
  <Toast group="login" @close="reload()" @life-end="reload()" position="center" :pt="{
    container: { class: 'bg-yellow-100' }
  }" />
</template>

<style></style>
