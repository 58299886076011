import { defineStore, storeToRefs } from "pinia";
import { ref } from 'vue';
import LicenseService from "@/services/license"; 
import { useUserStore } from './user';

export const useTagStore = defineStore('tag', () => {
  // --- State ---
  const ls = new LicenseService();

  const us = useUserStore()
  const { token } = storeToRefs(us)

  const tags = ref([]);
  const licenseTags = ref([]);
  const partnerTags = ref([]);
  const licensesForTag = ref([]);
  const loading = ref(false);
  const error = ref(null);

  // --- Actions ---

  async function fetchTags() {
    loading.value = true;
    try {
      const response = await ls.getAllPartnerTags(token.value);
      tags.value = response.data; 
      error.value = null;
    } catch (err) {
      error.value = err; 
    } finally {
      loading.value = false;
    }
  }

  async function createTag(tag) {
    loading.value = true;
    try {
      const response = await ls.createTag(token.value, tag);
      // tags.value.push(response.data); // Add the new tag to the list
      error.value = null;
    } catch (err) {
      error.value = err; 
    } finally {
      loading.value = false;
    }
  }

  async function deleteTag(tagID) {
    loading.value = true;
    try {
      await ls.deleteTag(token.value, tagID);
      tags.value = tags.value.filter(tag => tag.id !== tagID); // Remove the deleted tag
      error.value = null;
    } catch (err) {
      error.value = err; 
    } finally {
      loading.value = false;
    }
  }

    async function updateTag(tagData) {
        loading.value = true;
        try {
          const response = await ls.updateTag(token.value, tagData);

            tags.value = tags.value.map(tag => {
                if(tag.id === response.data.id){
                    return response.data
                }
                return tag
            })

          error.value = null;
        } catch (err) {
          error.value = err; 
        } finally {
          loading.value = false;
        }
      }



  async function fetchLicenseTags(licenseID) {
    loading.value = true;
    try {
      const response = await ls.getAllLicenseTags(token.value, licenseID);
      licenseTags.value = response.data;
      error.value = null;
    } catch (err) {
      error.value = err; 
    } finally {
      loading.value = false;
    }
  }

  async function addTagToLicense(licenseID, tagID) {
    loading.value = true;
    try {
      await ls.addTagToLicense(token.value, licenseID, tagID);
      // licenseTags.value.push({ id: tagID }); // Assuming the API returns the added tag ID
      error.value = null;
    } catch (err) {
      error.value = err;
    } finally {
      loading.value = false;
    }
  }

  async function addTagToLicenses(licenseIDs, tagID) {
    loading.value = true;
    try {
      await ls.addTagToLicenses(token.value, licenseIDs, tagID);
      // licenseTags.value.push({ id: tagID }); // Assuming the API returns the added tag ID
      error.value = null;
    } catch (err) {
      error.value = err;
    } finally {
      loading.value = false;
    }
  }
  

  async function removeTagFromLicense(licenseID, tagID) {
    loading.value = true;
    try {
      await ls.removeTagFromLicense(token.value, licenseID, tagID);
      licenseTags.value = licenseTags.value.filter(tag => tag.id !== tagID);
      error.value = null;
    } catch (err) {
      error.value = err; 
    } finally {
      loading.value = false;
    }
  }

  async function fetchLicensesForTag(tagID) {
    loading.value = true;
    try {
      const response = await ls.getAllLicensesForTag(token.value, tagID);
      licensesForTag.value = response.data;
      error.value = null;
    } catch (err) {
      error.value = err; 
    } finally {
      loading.value = false;
    }
  }

  async function getAllLicensesForTag(tagID) {
    const response = await ls.getAllLicensesForTag(token.value, tagID);   
    return response?.data?? [];
    // loading.value = true;
    // try {
    //   error.value = null;
    // } catch (err) {
    //   error.value = err; 
    // } finally {
    //   loading.value = false;
    //   return [];
    // }
  }

  return {
    tags, licenseTags, partnerTags, licensesForTag, loading, error,
    fetchTags, createTag, deleteTag, updateTag, fetchLicenseTags, 
    addTagToLicense, removeTagFromLicense, fetchLicensesForTag, addTagToLicenses,
    getAllLicensesForTag

  };
});
