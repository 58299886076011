import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Auth/Login.vue'
import Maintenance from '../views/Auth/Maintenance.vue'
import { useUserStore } from '../stores/user'
import { useLicenseStore } from '../stores/license'

import { storeToRefs } from "pinia"



const routesDefinition = {
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: to => ({ path: '/licenses' })
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: Maintenance
    },
    {
      path: '/licenses',
      name: 'licenses',
      component: () => import('../views/License/LicenseList.vue')
    },
    {
      path: '/expired_licenses',
      name: 'expired_licenses',
      component: () => import('../views/License/ExpiredLicenses.vue')
    },
    {
      path: '/inactive_licenses',
      name: 'inactive_licenses',
      component: () => import('../views/License/InactiveLicenses.vue')
    },
    {
      path: '/pending_licenses',
      name: 'pending_licenses',
      component: () => import('../views/License/PendingLicenses.vue')
    },
    {
      path: '/new_license',
      name: 'new_license',

      component: () => import('../views/License/NewLicense.vue')
    },
    {
      path: '/new_store/:serial?',
      name: 'new_store',
      component: () => import('../views/Store/StoreForm.vue'),
      props: true
    },
    {
      path: '/edit_store/:serial/:store_id',
      name: 'edit_store',
      component: () => import('../views/Store/StoreForm.vue'),
      props: true
    },
    {
      path: '/store_list/:serial/:certinfo?',
      name: 'store_list',
      component: () => import('../views/Store/StoreList.vue'),
      props: true
    }
  ]
}

if (import.meta.env.VITE_PLAYGROUND === 'true') {
  routesDefinition.routes.push({
    path: '/playground',
    name: 'playground',
    component: () => import('../views/Playground/Playground.vue')
  })
}



const router = createRouter(routesDefinition)


router.beforeEach(async (to, from) => {
  const userStore = useUserStore()
  const { isLogged, maintenance } = storeToRefs(userStore)


  if (to.name == 'maintenance') {
    return true
  }

  if (!isLogged.value && to.name !== 'login') {
    return { name: 'login' }
  }

  const licenseStore = useLicenseStore()

  console.log(from.name, to.name)

  if (
    (['licenses'].includes(from.name) && !['store_list', 'new_store', 'edit_store', 'new_license'].includes(to.name)) ||
    (['store_list', 'new_store', 'edit_store', 'new_license'].includes(from.name) && !['licenses'].includes(to.name))
  ) {
    licenseStore.resetFilters()
  }


})

export default router
