<template>
  <div class="left-nav">
    <div class="header">
      <div class="logo">
        <img alt="Vue logo" class="logo" src="@/assets/zone_soft_logo.svg" />
      </div>
      <div class="options">
        <Button @click="close" class="p-button-text">
          <img alt="Vue logo" src="@/assets/backburger.svg" />
        </Button>
      </div>
    </div>
    <div style="" class="user-box">
      <span>Login: {{ user.login }}</span>
      <span> {{ $t(`general.partner_id`) }}: {{ user.partner_id }}</span>
    </div>
    <div class="nav-menu">
      <PanelMenu
        @panel-open="onPanelOpen"
        @panel-close="onPanelClose"
        :expandedKeys="expandedKeys"
        :model="navigation"
      >
        <template #item="{ item }">
          <div
            @click="onClick(item)"
            :class="{ selected: !isClosed(item) }"
            class="menu-item"
          >
            <i :class="{ [`${item.icon}`]: true }" class="item pi"></i>
            <router-link class="label" v-if="item.to" :to="item.to">{{
              $t(`navigation.${item.key}`)
            }}</router-link>
            <a class="label" v-else>{{ $t(`navigation.${item.key}`) }}</a>
            <i
              v-if="item.items && isClosed(item)"
              class="indicator pi pi-angle-right"
            ></i>
            <i
              v-if="item.items && !isClosed(item)"
              class="indicator pi pi-angle-down"
            ></i>
          </div>
        </template>
      </PanelMenu>
    </div>
    <TagPanel :hidden="!isLicencesRoute" ></TagPanel>
    <div v-if="isSmall" class="logout-holder">
      <Button
        icon="pi pi-power-off"
        :label="$t('general.logout')"
        @click="logout"
      ></Button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";

import { useRouter } from "vue-router";

const router = useRouter();
const isLicencesRoute = ref(true);

// watch router changes
watch(
  () => router.currentRoute.value,
  (route) => {
   isLicencesRoute.value = route.name === "licenses";
  }
);


import TagPanel from "./TagPanel.vue";

import PanelMenu from "primevue/panelmenu";

import { useMediaQuery } from "@/use/media_query";


import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const emit = defineEmits(["close", "logout"]);

const props = defineProps(["nav", "selectedkeys"]);

const navigation = ref(props.nav);

const expandedKeys = ref(props.selectedkeys);

const tagItems =    ref({
        label: 'Devices',
        icon: 'pi pi-desktop',
        items: [
            {
                label: 'Phone',
                icon: 'pi pi-mobile'
            },
            {
                label: 'Desktop',
                icon: 'pi pi-desktop'
            },
            {
                label: 'Tablet',
                icon: 'pi pi-tablet'
            }
        ]
    }) 

watch(
  () => props.selectedkeys,
  (val) => {
    expandedKeys.value = val;
  }
);

const isClosed = (item) => {
  return (
    Object.keys(expandedKeys?.value ?? []).find((key) => {
      return key && key === (item?.key ?? "");
    }) === undefined
  );
};

const onPanelOpen = (event) => {
  // console.log(JSON.stringify(event))
};

const onPanelClose = (event) => {
  // console.log(JSON.stringify(event))
  // expandedKeys.value = [event.key]
};

const onClick = (item) => {
  expandedKeys.value = item.selectedKeys;
  if (item?.parent ?? false) {
    if (isSmall.value) close();
  }
};

const close = () => {
  emit("close");
};

const { sm, md, lg, xl } = useMediaQuery();

const isSmall = computed(() => {
  return sm.value || md.value;
});

const logout = () => {
  emit("logout");
};
</script>

<style lang="scss" scoped>
.left-nav {
  height: 100%;
  overflow: auto;
  background-color: #1e1e2d;
  //   padding: 1em;

  .header {
    height: 80px;
    // background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #333;
    .logo {
      padding-left: 10px;
      img {
        height: 30px;
      }
    }
  }

  .nav-menu {
    padding: 1em;
  }
  .menu-item {
    display: flex;
    gap: 1em;
    align-items: center;
    padding: 10px 10px;
    border-radius: 4px;
    background-color: #e84e0f;
    color: #fff;
    cursor: pointer;

    .label {
      flex: 1;
    }
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content {
    .menu-item {
      padding: 3px 3px;
      margin-left: 20px;
      margin-bottom: 2px;
      background-color: transparent;
      &.selected {
        background-color: #7e8299;
        color: #fff;
        text-decoration: dashed;
      }
      // background-color: #7E8299;
      color: #fff;
      padding-left: 10px;
      .label {
        color: #fff;
        text-decoration: none;
        font-size: 90%;
        margin-left: -10px;
      }
      .item {
        font-size: 40%;
      }
    }
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem:hover > .p-menuitem-content {
    .menu-item {
      background-color: #afb5da;
      color: #fff;
    }
  }
}

.logout-holder {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.user-box {
  color: white;
  display: flex;
  justify-content: start;
  padding: 20px 40px;
  border-bottom: 1px solid #333;
  flex-direction: column;
}
</style>
