const storage = window.localStorage;
// const storage = window.sessionStorage;

export default {
    setItem(key, value) {
        storage.setItem(key, value);
    },

    setJSONItem(key, value) {
        storage.setItem(key, JSON.stringify(value));
    },

    removeItem(key) {
        storage.removeItem(key);
    },
    clearItem() {
        storage.clear();
    },
    getItem(key, default_value = null) {
        const value = storage.getItem(key);
        return !value ? default_value : value;
    },

    getJSONItem(key, default_value = null) {
        const value = storage.getItem(key);
        if (value) return JSON.parse(value);
        else return default_value;
    },
};