import { defineStore, storeToRefs } from "pinia"

import { ref, computed } from 'vue'

import { useUserStore } from './user';


import LicenseService from "@/services/license"
import CombosService from "@/services/combos"

export const useLicenseStore = defineStore('license', () => {

    const us = useUserStore()
    const { token } = storeToRefs(us)

    const ls = new LicenseService()

    const cs = new CombosService()

    const licenses = ref([])
    const expiredLicenses = ref([])
    const inactiveLicenses = ref([])
    const pendingLicenses = ref([])
    const totalRecords = ref(0)

    const campainList = ref([])
    const selectedCampain = ref(null)
    const softwareList = ref([])
    const hardwareList = ref([])
    const countryList = ref([])

    const list_page = ref(0)
    const companyName = ref("");
    const nif = ref("");
    const clientNumber = ref("");
    const software = ref(-1);
    const serialNumber = ref("");
    const state = ref(1);
    const visible_state = ref(-1);

    function resetFilters() {
        list_page.value = 0;
        companyName.value = "";
        nif.value = "";
        clientNumber.value = "";
        software.value = -1;
        serialNumber.value = "";
        state.value = 1;
    }

    async function filterLicenses(empresa, contribuinte, cliente, software, serial, state, page, take, infinite = false) {
        try {
            const data = await ls.filterLicenceList(token.value, empresa, contribuinte, cliente, software, serial, state, page, take)
            const list = data?.data ?? []
            licenses.value = infinite ? [...licenses.value, ...list] : list
            totalRecords.value = data.meta.itemCount
            return list;
        } catch (error) {
            licenses.value = infinite ? licenses.value : [];
        }
    }

    async function getLicenses2(search, page, take, infinite = false) {
        try {

            const data = await ls.getLicenseList(token.value, search, page, take)
            const list = data?.data ?? []
            licenses.value = infinite ? [...licenses.value, ...list] : list
            totalRecords.value = data.meta.total_items
            return list;
        } catch (error) {
            licenses.value = infinite ? licenses.value : []
        }
    }

    async function getCampainList() {
        try {
            const data = await cs.getCampainList(token.value)

            campainList.value = data.campanhas?.map(e => {
                if (!e.id) {
                    return { ...e, id: -1, }
                }
                return { ...e, descricao: `${e.descricao} - ${e.id}` }
            }) || []


            softwareList.value = data.softwares?.map(e => {
                return { ...e, descricao: `${e.descricao} - ${e.codigo}` }
            }) || []


            return data
        } catch (error) {
            campainList.value = []
        }
    }

    async function getCampainDetail(campainId, software = 0, version = 0, countryId = 'PT') {
        try {
            const data = await cs.getCampainDetail(token.value, campainId, software, version, countryId)
            selectedCampain.value = data
            hardwareList.value = (data?.hardware ? [data.hardware] : []).map(e => {
                return { ...e, descricao: `${e.descricao} - ${e.codigo}`, hardware: e.codigo }
            }) || []

            return data
        } catch (error) {
            selectedCampain.value = null
            return null
        }
    }



    async function getSoftwareList() {
        try {
            const data = await cs.getSofwareList(token.value)
            softwareList.value = data.map(e => {
                return { ...e, descricao: `${e.descricao} - ${e.codigo}` }
            })
            return data
        } catch (error) {
            softwareList.value = []
        }
    }

    async function getHardwareList(softwareId, countryId = 'PT') {
        try {
            const data = await cs.getHardwareList(token.value, softwareId, countryId)
            hardwareList.value = data.map(e => {
                return { ...e, descicao: `${e.descricao} - ${e.hardware}` }
            })
            return data
        } catch (error) {
            hardwareList.value = []
        }
    }

    async function orderLicense(license) {
        try {
            const data = await ls.orderLicense(token.value, license)
            return {
                success: true,
                message: "new_license.order_success",
                data: data
            }
        } catch (error) {
            return {
                success: false,
                message: "new_license.order_error",
                data: error
            }
        }
    }


    async function getExpiringLicenceList() {
        try {
            const data = await ls.getExpiringLicenceList(token.value)
            expiredLicenses.value = data.data.filter(e => e.software != 13) // not include ZSGO
            return data
        } catch (error) {
            console.log("getExpiringLicenceList", error)
            expiredLicenses.value = []
        }
    }

    async function getInactiveLicenceList() {
        try {
            const data = await ls.getInactiveLicenceList(token.value)
            inactiveLicenses.value = data.data
            return data
        } catch (error) {

            console.log("getInactiveLicenceList", error)
            inactiveLicenses.value = []
        }
    }

    async function getPendingLicenceList() {
        try {
            const data = await ls.getPendingLicenceList(token.value)
            pendingLicenses.value = data.data
            return data
        } catch (error) {

            console.log("getPendingLicenceList", error)
            pendingLicenses.value = []
        }
    }


    //disableLicense function
    async function disableLicense(licenseId) {
        try {
            const data = await ls.disableLicense(token.value, licenseId)
            return {
                success: true,
                message: "license_disable.success",
                data: data
            }
        } catch (error) {
            return {
                success: false,
                message: "license_disable.error",
                data: error
            }
        }
    }

    //enableLicense function
    async function enableLicense(licenseId) {
        try {
            const data = await ls.enableLicense(token.value, licenseId)
            return {
                success: true,
                message: "license_enable.success",
                data: data
            }
        } catch (error) {
            return {
                success: false,
                message: "license_enable.error",
                data: error
            }
        }
    }



    async function getLicenseFile(licenceData, renew = false) {


        try {
            const data = await ls.getLicenseFile(token.value, licenceData, renew)
            return {
                success: true,
                message: "license_file.success",
                data: data
            }
        } catch (error) {
            return {
                success: false,
                message: "license_file.error",
                data: error
            }
        }
    }

    async function exportLicenceList(licdata) {

        try {
            const data = await ls.getExportFile(token.value, licdata)
            return {
                success: true,
                message: "license_file.success",
                data: data
            }
        } catch (error) {
            return {
                success: false,
                message: "license_file.error",
                data: error
            }
        }
    }


    async function renewLicense(renew_data) {
        try {
            const data = await ls.renewLicense(token.value, renew_data)
            return {
                success: true,
                message: "license_renew.success",
                data: data
            }
        } catch (error) {
            return {
                success: false,
                message: "license_renew.error",
                data: error
            }
        }
    }

    async function renewZSFACTLicense(renew_data) {
        try {
            const data = await ls.renewZSFACTLicense(token.value, renew_data)
            return {
                success: true,
                message: "license_renew.success",
                data: data
            }
        } catch (error) {
            return {
                success: false,
                message: "license_renew.error",
                data: error
            }
        }
    }


    async function checkNif(nif) {
        try {
            const data = await ls.checkNif(token.value, nif)
            return {
                success: true,
                message: "check_nif.order_success",
                data: data
            }
        } catch (error) {
            return {
                success: false,
                message: error.response.data.message || "check_nif.order_error",
                data: error
            }
        }
    }

    async function checkForExistingLicense(software_id, nif) {
        if (!nif || !software_id) return false;
        try {
            const data = await ls.checkForExistingLicense(token.value, software_id, nif)
            return {
                success: true,
                message: "check_software",
                data: data
            }
        } catch (error) {
            return {
                success: false,
                message: error.response.data.message || "check_software_error",
                data: error
            }
        }
    }

    async function getZSGOLicenseData(serial) {

        try{
            const data = await ls.getZSGOLicenceData(token.value, serial)
            return data.data
        }catch (error) {
            console.log("getZSGOLicenseData -> error", error)
        }
    }

    async function orderZSGOLicenseChange(data) {
        try{
            const response = await ls.orderZSGOLicenseChange(token.value, data)
            return {
                success: true,
                message: "orderZSGOLicenseChange_success",
                data: response
            }
        }catch (error) {
            return {
                success: false,
                message: error.response.data.message || "orderZSGOLicenseChange_error",
                data: error
            }
        }
    }


    return {
        filterLicenses
        , getLicenses2, licenses, totalRecords, getCampainList, campainList, getSoftwareList, softwareList, getHardwareList, hardwareList, getCampainDetail, selectedCampain, orderLicense, getExpiringLicenceList, expiredLicenses,
        getInactiveLicenceList, inactiveLicenses, getLicenseFile, renewLicense, checkNif, countryList, getPendingLicenceList, pendingLicenses, disableLicense, enableLicense, list_page,
        companyName, nif, clientNumber, software, serialNumber, state,visible_state, resetFilters, exportLicenceList, renewZSFACTLicense, checkForExistingLicense, getZSGOLicenseData, orderZSGOLicenseChange
    }

})


