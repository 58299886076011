<template>
  <div>
    <LeftBar :visible="visibleLeft && !loginPage && !maintenancePage">
      <LeftNav @close="toggleLeft" @logout="logout" :selectedkeys="selectedkeys" :nav="navMenu"></LeftNav>
    </LeftBar>

    <MainArea :shrink="!isSmall" :expanded="!visibleLeft || loginPage || maintenancePage">
      <div v-if="!loginPage && !maintenancePage" class="header">
        <div v-if="!visibleLeft" class="options">
          <Button @click="toggleLeft" class="p-button-text">
            <img :class="{ big: isSmall }" style="transform: rotate(180deg)" alt="ZSLic" src="@/assets/backburger.svg" />
          </Button>
        </div>
        <div @click="goBack" class="logo-holder">
          <img alt="ZSLIC LOGO" class="logo" src="@/assets/zslic_logo.svg" />
        </div>
        <div v-if="!isSmall" class="partner-name">
          <h2>{{ user.partner_name }}</h2>
        </div>
        <!-- <div class="user-name">Mikas</div> -->
        <div class="header-options">
          <!-- <div class="user-options">
            <Dropdown v-model="$i18n.locale" :options="languageList" optionLabel="name" optionValue="code"
              placeholder="Select language" />
          </div> -->

          <Button text icon="pi pi-user-edit" :label="$t('partner_data.title')"
            @click="editPartnerData"></Button><!-- ZSLW-66 -->

          <Button v-if="!isSmall" text icon="pi pi-power-off" :label="$t('general.logout')" @click="logout"></Button>

          <Button style="

      background-color: transparent;
      /* font-size: 200%; */
      margin: 0;
      margin-top: 10px;
      margin-right: 40px;
      padding: 0;
      border: none;
      outline-color: transparent;
      outline-style: dashed;
    " @click="toggle">
            <span v-if="$i18n.locale == 'pt'"><img alt="Português" class="flag" src="@/assets/portugal.png" /></span>
            <span v-if="$i18n.locale == 'es'"><img alt="Español" class="flag" src="@/assets/spain.png" /></span>
            <span v-if="$i18n.locale == 'en'"><img alt="English" class="flag" src="@/assets/united-kingdom.png" /></span>
            <span v-if="$i18n.locale == 'fr'"><img alt="Francais" class="flag" src="@/assets/france-flag-icon.webp" /></span>
          </Button>
          <OverlayPanel ref="op">
            <ul class="flag-list">
              <li @click="changeLanguage('pt', true)"><img alt="Português" class="flag" src="@/assets/portugal.png" />
              </li>
              <li @click="changeLanguage('es', true)"><img alt="Español" class="flag" src="@/assets/spain.png" /></li>
              <li @click="changeLanguage('en', true)"><img alt="English" class="flag" src="@/assets/united-kingdom.png" />
              </li>
              <li @click="changeLanguage('fr', true)"><img alt="English" class="flag" src="@/assets/france-flag-icon.webp" />
              </li>
            </ul>
          </OverlayPanel>


        </div>
      </div>
      <!-- <Button @click="toggleLeft">&lt;&lt;</Button>
      <Button @click="toggleRight">&gt;&gt;</Button> -->

      <router-view></router-view>
    </MainArea>
    <RightBar :visible="visibleRight">
      <Button @click="toggleRight">x</Button>
    </RightBar>
    <!-- ZSLW-66-->
    <Dialog v-model:visible="keyFormVisible" modal :header="$t('partner_data.title')">
      <div style="max-width: min(100vw, 1000px)" class="grid">
        <div class="grid col-12">
          <TextInput :label="$t('partner_data.name')" disabled class="col-12 md:col-8" v-model="partner.nome"></TextInput>
          <TextInput :label="$t('partner_data.nif')" disabled class="col-12 md:col-4" v-model="partner.contribuinte">
          </TextInput>
        </div>
        <div class="grid col-12">
          <TextInput :label="$t('partner_data.address')" class="col-12 md:col-12" v-model="partner.morada"></TextInput>
          <TextInput :label="$t('partner_data.zip')" class="col-12 md:col-4" v-model="partner.cp1"></TextInput>
          <TextInput label="" class="col-12 md:col-2" v-model="partner.cp2"></TextInput>
          <TextInput :label="$t('partner_data.local')" class="col-12 md:col-6" v-model="partner.cp3"></TextInput>
        </div>
        <div class="grid col-12">
          <TextInput :label="$t('partner_data.contact')" class="col-12 md:col-12" v-model="partner.contacto"></TextInput>
          <TextInput :label="$t('partner_data.phone')" class="col-12 md:col-6" v-model="partner.telefone"></TextInput>
          <TextInput :label="$t('partner_data.mobile')" class="col-12 md:col-6" v-model="partner.movel"></TextInput>
        </div>
        <div class="grid col-12">
          <TextInput :label="$t('partner_data.email')" class="col-12 md:col-6" v-model="partner.email"></TextInput>
          <TextInput :label="$t('partner_data.www')" class="col-12 md:col-6" v-model="partner.web"></TextInput>
        </div>
      </div>
      <template #footer>
        <Button :label="$t('partner_data.cancel')" icon="pi pi-times" class="p-button-text"
          @click="keyFormVisible = false" text />
        <Button :label="$t('partner_data.update')" icon="pi pi-check" @click="savePartnerData" />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import LeftNav from "./navigation/LeftNav.vue";
import Footer from "./Footer.vue";
import OverlayPanel from "primevue/overlaypanel";
import { ref, computed, watch, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useMediaQuery } from "@/use/media_query";
import { useUserStore } from "@/stores/user";
import { useMiscStore } from "@/stores/misc";

const userStore = useUserStore();

const { user, partner } = storeToRefs(userStore);

const miscStore = useMiscStore();

import Dropdown from "primevue/dropdown";

const op = ref();
const toggle = (event) => {
  op.value.toggle(event);
};

import nav from "./navigation";
import { storeToRefs } from "pinia";
import Dialog from "primevue/dialog";

import { useToast } from "primevue/usetoast";

const toast = useToast();

import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();

// watch(locale, (val) => {
//   localStorage.setItem("lang", val);
// });

//ZSLW-66
async function savePartnerData() {
  const resp = await userStore.savePartner();

  if (resp.status === "ok") {
    toast.add({
      severity: "success",
      summary: t("partner_data.success"),
      group: "c",
      detail: "",
      life: 6000,
    });
    keyFormVisible.value = false;
  }


}
//ZSLW-66
async function editPartnerData() {
  await userStore.getPartner();
  keyFormVisible.value = true;
}

function tranverseNav(nav, key) {
  nav = {
    ...nav,
    selectedKeys: key ? { ...key, [nav.key]: true } : { [nav.key]: true },
  };
  if (nav.items) {
    nav.items = nav.items.map((item) =>
      tranverseNav(item, { [nav.key]: true })
    );
  }
  return nav;
}

const navMenu = computed(() => {
  return nav.map((n) => tranverseNav(n));
});

const keyFormVisible = ref(false);

const route = useRoute();
const router = useRouter();

const visibleLeft = ref(true);
const visibleRight = ref(false);
const shrinkable = ref(true);
const currentPath = ref(route.path);
const selectedkeys = ref({});

const currentLanguage = ref("pt");

const languageList = [
  { name: "Português", code: "pt" },
  { name: "Español", code: "es" },
  { name: "English", code: "en" },
];


const toggleLeft = () => {
  visibleLeft.value = !visibleLeft.value;
};

const toggleRight = () => {
  visibleRight.value = !visibleRight.value;
};

const { sm, md, lg, xl } = useMediaQuery();

const isSmall = computed(() => {
  return sm.value || md.value;
});

const loginPage = computed(() => {
  return route.path === "/login";
});

const maintenancePage = computed(() => {
  return route.path === "/maintenance";
});

//watch for route change
watch(
  () => route.path,
  (val) => {
    currentPath.value = val;
    selectedkeys.value = findRoute(navMenu.value);
  }
);

const findRoute = (root) => {
  for (let i = 0; i < root.length; i++) {
    const e = root[i];
    if (currentPath.value.startsWith(e.to)) {
      return e.selectedKeys;
    }
    if (e.items && e.items.length > 0) {
      return findRoute(e.items);
    }
  }
  return undefined;
};

onMounted(() => {
  if (isSmall.value) {
    visibleLeft.value = false;
  }
  miscStore.getAllSoftwareList()
});

const logout = () => {
  userStore.logout();
  router.push("/login");
};

const goBack = () => {
  router.push({ path: `/` });
};

const changeLanguage = (lang, t) => {
  locale.value = lang;
  localStorage.setItem("lang", lang);
  if (t) op.value.toggle();
};
</script>

<style lang="scss">
.header {
  height: 80px;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .options {
    img {
      &.big {
        width: 50px;
      }
    }
  }

  .logo-holder {
    cursor: pointer;
    margin-left: 20px;

    .logo {
      width: 100px;
    }
  }
}

.header-options {
  display: flex;
  gap: 10px;
  padding-right: 20px;
}

ul.flag-list {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    cursor: pointer;
    font-size: 180%;
  }
}
</style>
