<template>
  <div :class="$attrs.class" class="flex gap-2 flex-column">
    <label for="username1">{{ label }}</label>
    <Dropdown :style="$attrs.style" v-model="dropdownValue" :options="$attrs.options" :optionLabel="$attrs.optionLabel"
      :optionValue="$attrs.optionValue" :placeholder="$attrs.placeholder" :disabled="$attrs.disabled"
      :showClear="$attrs.showClear" :filter="$attrs.filter" :class="{ 'p-invalid': error }" />
    <small style="color: red">{{ error || validationRule }}</small>
  </div>
</template>

<script>
// use normal <script> to declare options
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { ref, watch } from "vue";

import { useValidator } from "@/use/validator";

import { useI18n } from "vue-i18n";

const { t } = useI18n();

const validationRule = ref("");

const { validate } = useValidator(t);

const emit = defineEmits(["update:modelValue", "error", "bb"]);

const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  modelValue: {
    type: [String, Number, Array, Object, Boolean],
    default: "",
  },
  rules: {
    type: String,
    default: "",
  },
  error: {
    type: String,
    default: "",
  },
});

const dropdownValue = ref(props.modelValue);

watch(
  () => props.modelValue,
  (val) => {
    dropdownValue.value = val;
    doValidation();
  }
);

watch(
  () => dropdownValue.value,
  (val) => {
    emit("update:modelValue", val);
    emit("bb", val);
  }
);

const doValidation = () => {
  if (!props.rules) {
    emit("error", "");
    return "";
  }
  const error = validate(dropdownValue.value, props.rules);
  emit("error", error);
  validationRule.value = error;
};

doValidation();
</script>

<style lang="scss" scoped></style>
