import { createApp } from 'vue'
import { createPinia } from 'pinia'
import PrimeVue from 'primevue/config';

import { createI18n } from 'vue-i18n'
// import translation from "./i18n/translation"

import App from './App.vue'
import router from './router'

import Tooltip from 'primevue/tooltip';
import Button from "primevue/button"
import InputText from "primevue/inputtext";
import Textarea from 'primevue/textarea';
import FileUpload from 'primevue/fileupload';
import MainArea from "@/layout/MainArea.vue";
import LeftBar from "@/layout/LeftBar.vue";
import RightBar from "@/layout/RightBar.vue";
import Dropdown from "primevue/dropdown";
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import Toast from 'primevue/toast';
import Panel from "@/components/common/Panel.vue";
import Input from "@/components/common/form/Input.vue";
import Select from "@/components/common/form/Select.vue";
import 'primeflex/primeflex.css'
import './assets/main_theme.scss'
import 'primevue/resources/primevue.css'                // core css
import 'primeicons/primeicons.css'
import './assets/zs_theme.scss'                   // icons

import validationRules from './plugins/i18n-v-rules.js'

async function loadJSON(path) {

    const response = await fetch(path)
    return await response.json()

}

const translation = await loadJSON('/translation3.json?hash=' + _APP_COMMIT_HASH_)

const crules = await loadJSON('/country_rules.json')

// const translationLocal = await loadJSON('/translation.json')
// const system = await loadJSON('/system_messages.json')

// const translation = {
//     pt: { ...translationLocal.pt, ...system.pt },
//     en: { ...translationLocal.en, ...system.en },
//     es: { ...translationLocal.es, ...system.es }
// }



// const translation = {
//     pt: { ...translationLocal.pt, syste12345m: { ...system.pt } },
//     en: { ...translationLocal.en, system: { ...system.en } },
//     es: { ...translationLocal.es, system: { ...system.es } }
// }

// 2. Create i18n instance with options
const i18n = createI18n({
    legacy: false,
    locale: 'pt', // set locale
    fallbackLocale: 'pt', // set fallback locale
    messages: translation, // set locale messages
    // If you need to specify other options, you can set other options
    // ...
})

const app = createApp(App)

app.use(validationRules, crules)

app.use(createPinia())
app.use(i18n)
import './services/axios.interceptor.js';
app.use(PrimeVue, { ripple: true })
app.use(ToastService);
app.use(ConfirmationService);
app.use(router)

app.component('Button', Button)
app.component('InputText', InputText)
app.component('Textarea', Textarea)
app.component('FileUpload', FileUpload)
app.directive('tooltip', Tooltip)
app.component('MainArea', MainArea)
app.component('LeftBar', LeftBar)
app.component('RightBar', RightBar)
app.component('Panel', Panel)
app.component('Dropdown', Dropdown)
app.component('Toast', Toast)
app.component('TextInput', Input)
app.component('SelectInput', Select)


app.mount('#app')
