


// import './axios.interceptor.js';
import axios from 'axios';
import api_url from './api_url';



export default class LicenseService {

    constructor(base_url) {
        if (base_url) {
            this.base_url = base_url;
        } else {
            this.base_url = api_url;
        }
    }




    async getLicenseList(token, search, page, take) {


        let str = '';


        if (search && Object.keys(search).length > 0) {
            str = Object.keys(search).map((key) => {
                return `${key}=${search[key]}`;
            }).join('&');
        }

        const p = page ? page : 1;
        const t = take ? take : 10;

        const response = await axios.get(`${this.base_url}/v1/license?${str}&p=${p}&pp=${t}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;

    }

    async filterLicenceList(token, empresa, contribuinte, cliente, software, serial, state, page, take) {

        const conditions = {};
        if (empresa) {
            conditions.empresa = empresa;
        }
        if (contribuinte) {
            conditions.contribuinte = contribuinte;
        }
        if (cliente) {
            conditions.cliente = cliente;
        }
        if (software != -1) {
            conditions.software = software;
        }
        if (serial) {
            conditions.serial = serial;
        }
        if (state != -1) {
            conditions.ativo = state;
        }

        const response = await axios.post(`${this.base_url}/licenses/filter?page=${page}&take=${take}`, {
            conditions: conditions
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    }



    async getLicenseList2(token, search = "", page, take) {
        const response = await axios.post(`${this.base_url}/licenses/search?page=${page}&take=${take}`,
            {
                conditions: {
                    str: search
                }
            }
            , {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
        return response.data;
    }

    async orderLicense(token, license) {
        const response = await axios.post(`${this.base_url}/v1/license`, license, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    }

    async getExpiringLicenceList(token) {
        const response = await axios.get(`${this.base_url}/v1/license/expiring`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    }

    async getPendingLicenceList(token) {
        const response = await axios.get(`${this.base_url}/v1/license/pending`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    }

    //disableLicense
    async disableLicense(token, id) {
        const response = await axios.put(`${this.base_url}/v1/license/disable/${id}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    }

    //enableLicense
    async enableLicense(token, id) {
        const response = await axios.put(`${this.base_url}/v1/license/enable/${id}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    }



    async getInactiveLicenceList(token) {
        const response = await axios.get(`${this.base_url}/v1/license/inactive`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    }

    async getLicenseFile(token, data, renew = false) {


        axios({
            url: `${this.base_url}/v1/license/file/${renew}`, //your url
            method: 'POST',
            responseType: 'blob', // important
            data: data,
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            const filename = data.descricao + '.aut';
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });

        // const response = await axios.post(`${this.base_url}/v1/license/file`, data, {
        //     headers: {
        //         Authorization: `Bearer ${token}`
        //     }
        // });
        // return response.data;
    }

    async renewLicense(token, data) {
        const response = await axios.post(`${this.base_url}/v1/license/renew`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    }

    async renewZSFACTLicense(token, data) {
        const response = await axios.post(`${this.base_url}/v1/license/renew_zsfact`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    }

    async checkNif(token, nif) {
        const response = await axios.get(`${this.base_url}/v1/store/nif/${nif}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    }

    async checkForExistingLicense(token, software_id, nif) {

        const response = await axios.get(`${this.base_url}/v1/license/persoft/${software_id}/${nif}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    }


    async getExportFile(token, data) {

        const software = data.software && data.software != -1 ? "&software=" + data.software : '';
        let order = data.order ? "&order=" + data.order : '&order=nome';
        order += data.tags? '&tags=' + data.tags : '';


        axios({
            url: `${this.base_url}/v1/license/export/csv?sc=AND${software}&ativo=1&visible=1${order}&pp=20000`, //your url
            method: 'GET',
            responseType: 'blob', // important
            data: data,
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            // create a striung based on todays date ikn the format dd-mm-yyyy_hh-mm-ss
            const date = new Date();
            const filename = 'license_export_' + date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear() + '_' + date.getHours() + '-' + date.getMinutes() + '-' + date.getSeconds() + '.csv';
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });

        // const response = await axios.post(`${this.base_url}/v1/license/file`, data, {
        //     headers: {
        //         Authorization: `Bearer ${token}`
        //     }
        // });
        // return response.data;
    }


    async getZSGOLicenceData(token, serial) {
        let url = `${api_url}/v1/license/zsgo/${serial}`
        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;

    }

    async orderZSGOLicenseChange(token, data) {
        const response = await axios.post(`${this.base_url}/v1/license/zsgo`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    }

    async createTag(token, tag) {

        const payload = tag

        const response = await axios.post(`${this.base_url}/v1/tags`, payload, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    }

    async deleteTag(token, tagID) {
        const response = await axios.delete(`${this.base_url}/v1/tags/${tagID}`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    }
    
    async updateTag(token, tagData) {
        //tagData = {tagID, tagName} 
        const response = await axios.put(`${this.base_url}/v1/tags`, tagData, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    }


    async getAllPartnerTags(token) {
        const response = await axios.get(`${this.base_url}/v1/partner-tags`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    }

    async addTagToLicense(token, licenseID, tagID) {
        const response = await axios.post(`${this.base_url}/v1/licenses/${licenseID}/tags/${tagID}`, {}, { // Empty object as body
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    }

    async removeTagFromLicense(token, licenseID, tagID) {
        const response = await axios.delete(`${this.base_url}/v1/licenses/${licenseID}/tags/${tagID}`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    }

    async addTagToLicenses(token, licenseIDs, tagID) {
        const response = await axios.post(`${this.base_url}/v1/licenses/tags`, { 
            license_ids: licenseIDs,
            tag_id: tagID
        }, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    }

    async getAllLicenseTags(token, licenseID) {
        const response = await axios.get(`${this.base_url}/v1/licenses/${licenseID}/tags`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    }
     //get all licences for tag
     async getAllLicensesForTag(token, tagID) {
        const response = await axios.get(`${this.base_url}/v1/tags/${tagID}/licenses`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    }    


}